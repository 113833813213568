import axios from "./index";

export default {
  getAll() {
    return axios.get("/v1/blog_temp");
  },
  getDetail(uuid) {
    return axios.get(`/v1/blog_temp/${uuid}`);
  },
  getAllTag() {
    return axios.get("/v1/blog/tags");
  },
  createBlog(data) {
    return axios.post("/v1/blog_temp", data);
  },
  updateBlog(data) {
    return axios.put("/v1/blog_temp", data);
  },
  sendApproveBlog(data) {
    return axios.post("/v1/blog_temp/send_approval", data);
  },
  verifyBlog(data) {
    return axios.post("/v1/blog_temp/verify_approval", data);
  },
  uploadFile(formData) {
    return axios.post(`/v1/blog_temp/upload`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
};
