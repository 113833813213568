<script>
import Datepicker from "vue3-datepicker";
// import DropZone from "@/components/widgets/dropZone";
import { ref } from "vue";

import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import Multiselect from "@vueform/multiselect";
import draggable from "vuedraggable";

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import useVuelidate from "@vuelidate/core";
import categorySvc from "@/services/category";
import blogEditSvc from "@/services/blog-edit";
import { toast } from "vue3-toastify";
import { DateTime } from "luxon";
import MyUploadAdapter from "@/services/upload-adapter";
import { required, helpers, requiredIf } from "@vuelidate/validators";

/**
 * Form validation component
 */
export default {
  page: {
    title: "Manage Blog",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Datepicker,
    // DropZone,
    Layout,
    PageHeader,
    ckeditor: CKEditor.component,
    Multiselect,
    draggable,
  },
  data() {
    return {
      title: "Manage Blog (Request For Edit)",
      role:
        localStorage.getItem("userInfo") !== null
          ? JSON.parse(localStorage.getItem("userInfo")).role
          : "user",
      items: [
        {
          text: "Blog",
          href: "list-edit",
        },
        {
          text: "Manage Blog (Request For Edit)",
          active: true,
        },
      ],
      hiddenButtonSave: true,
      isDisabled: false,
      categories: [],
      categoriesOption: [],
      tagsOptions: [],
      fieldsDefaultForCategory: [],
      fieldsOptionalForCategory: [],
      blogForm: {
        uuid: null,
        category: "",
        name: "",
        locationName: "",
        startDate: "",
        endDate: "",
        date: ref(new Date()),
        phone: "",
        tag: [],
        detail: "",
        fields: [],
        statusCode: "",
        images: [],
        thumbnail: "",
        attachments: [],
      },
      submitted: false,
      submit: false,
      editor: ClassicEditor,
      isSendApprove: false,
      showModalApprove: false,
      approve: {
        remark: "",
      },
      showModalReject: false,
      reject: {
        remark: "",
      },
    };
  },
  setup() {
    return { v$: useVuelidate() };
  },
  computed: {
    lowerDate() {
      return this.blogForm.startDate !== ""
        ? this.blogForm.startDate
        : new Date();
    },
  },
  validations: {
    blogForm: {
      category: {
        required: helpers.withMessage("Blog Category is required", required),
      },
      name: {
        required: helpers.withMessage("Blog Name is required", required),
      },
      tag: {
        required: helpers.withMessage("Blog Tag is required", required),
      },
      thumbnail: {
        required: requiredIf(function () {
          return this.blogForm.thumbnail.length == 0; // New changes
        }),
      },
      detail: {
        required: helpers.withMessage("Detail is required", required),
      },
    },
    reject: {
      remark: {
        required: helpers.withMessage("Remark is required", required),
      },
    },
  },
  mounted() {
    this.getCategory();
  },
  methods: {
    getCategory() {
      categorySvc
        .getAll()
        .then((resp) => {
          this.categories = resp.data.data;
          this.categoriesOption = resp.data.data.map((category) => {
            return { value: category.uuid, label: category.name };
          });
        })
        .finally(() => {
          this.getTag();
        });
    },
    getTag() {
      blogEditSvc
        .getAllTag()
        .then((resp) => {
          this.tagsOptions = resp.data.data.map((tag) => {
            return { value: tag.value, label: tag.text };
          });
        })
        .finally(() => {
          if (this.$route.query.uuid) {
            this.get(this.$route.query.uuid);
          } else {
            this.hiddenButtonSave = false;
          }
        });
    },
    get(uuid) {
      blogEditSvc
        .getDetail(uuid)
        .then((resp) => {
          const blog = resp.data.data;
          this.blogForm.uuid = uuid;
          this.blogForm.category = blog.categoryUUID;
          this.blogForm.name = blog.name;
          this.blogForm.statusCode = blog.statusCode;
          this.blogForm.images = blog.images === null ? [] : blog.images;
          this.blogForm.startDate = blog.startDate
            ? ref(new Date(blog.startDate))
            : "";
          this.blogForm.endDate = blog.endDate
            ? ref(new Date(blog.endDate))
            : "";
          this.blogForm.tag = blog.tags.map((tag) => {
            return tag.tagCode;
          });
          this.blogForm.fields = blog.fields === null ? [] : blog.fields;
          this.blogForm.detail = blog.detail;
          this.blogForm.attachments =
            blog.attachments === null ? [] : blog.attachments;
          this.blogForm.thumbnail = blog.thumbnail;
          this.setFieldForCategory(blog.categoryUUID);
          if (
            [
              "pending_verify",
              "approved",
              "publish",
              "unpublish",
              "editing",
              "publish_draft",
            ].includes(blog.statusCode)
          ) {
            this.isDisabled = true;
          } else {
            this.hiddenButtonSave = false;
            this.isDisabled = false;
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error("data not found", {
            autoClose: localStorage.getItem("toastCountdown"),
          });

          this.$router.push("list-edit");
        });
    },
    setFieldForCategory(uuid) {
      const category = this.categories.filter((category) => {
        return category.uuid === uuid;
      });

      const fieldsDefault = [];
      const fieldsOptional = [];
      const fieldsValue = [];
      if (this.blogForm.fields.length) {
        this.blogForm.fields.forEach((field) => {
          fieldsValue[field.fieldUUID] =
            field.fieldInputType === "date"
              ? ref(new Date(field.fieldValue))
              : field.fieldValue;
        });
      }

      if (category.length && category[0].fieldsDefault !== null) {
        category[0].fieldsDefault.forEach((field) => {
          fieldsDefault.push({
            uuid: field.fieldUuid,
            type: field.fieldType,
            name: field.fieldName,
            inputType: field.inputType,
            priority: field.priority,
            isRequired: field.isRequired,
            value: fieldsValue[field.fieldUuid]
              ? fieldsValue[field.fieldUuid]
              : null,
          });
        });
      }

      if (category.length && category[0].fieldsOptional !== null) {
        category[0].fieldsOptional.forEach((field) => {
          fieldsOptional.push({
            uuid: field.fieldUuid,
            type: field.fieldType,
            name: field.fieldName,
            inputType: field.inputType,
            priority: field.priority,
            isRequired: field.isRequired,
            value: fieldsValue[field.fieldUuid]
              ? fieldsValue[field.fieldUuid]
              : null,
          });
        });
      }

      this.fieldsDefaultForCategory = fieldsDefault;
      this.fieldsOptionalForCategory = fieldsOptional;
    },
    uploadThumbnail() {
      const formData = new FormData();

      for (const element of this.$refs.thumbnail.files) {
        let file = element;
        formData.append("images", file);
      }

      blogEditSvc
        .uploadFile(formData)
        .then((resp) => {
          this.blogForm.thumbnail = resp.data.data[0].url;
        })
        .finally(() => {
          this.$refs.thumbnail.value = "";
        });
    },
    uploadAttachment() {
      const formData = new FormData();

      for (const element of this.$refs.attachments.files) {
        let file = element;
        formData.append("images", file);
      }

      blogEditSvc
        .uploadFile(formData)
        .then((resp) => {
          resp.data.data.forEach((attachment) => {
            this.blogForm.attachments.push({
              url: attachment.url,
              originName: attachment.originName,
            });
          });
        })
        .finally(() => {
          this.$refs.attachments.value = "";
        });
    },
    onEditorReady(editor) {
      editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
        return new MyUploadAdapter(loader);
      };
    },
    uploadImage() {
      const formData = new FormData();

      for (const element of this.$refs.file.files) {
        let file = element;
        formData.append("images", file);
      }

      blogEditSvc
        .uploadFile(formData)
        .then((resp) => {
          resp.data.data.forEach((image) => {
            this.blogForm.images.push({ uuid: image.url, url: image.url });
          });
        })
        .finally(() => {
          this.$refs.file.value = "";
        });
    },
    handleApprove() {
      this.submitted = true;
      this.reject.remark = "manage-blog";
      const data = {
        blogUUID: this.blogForm.uuid,
        status: "approved",
        isPublish: true,
        remark: this.approve.remark,
      };

      blogEditSvc
        .verifyBlog(data)
        .catch((err) => {
          toast.error(err, {
            autoClose: localStorage.getItem("toastCountdown"),
          });
        })
        .finally(() => {
          this.showModalApprove = false;
          this.submitted = false;
          toast.success("Approve Successfully", {
            autoClose: localStorage.getItem("toastCountdown"),
          });
          this.$router.push("list-edit");
        });
    },
    handleReject() {
      this.submitted = true;
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      } else {
        const data = {
          blogUUID: this.blogForm.uuid,
          status: "rejected",
          isPublish: false,
          remark: this.reject.remark,
        };

        blogEditSvc
          .verifyBlog(data)
          .catch((err) => {
            toast.error(err, {
              autoClose: localStorage.getItem("toastCountdown"),
            });
          })
          .finally(() => {
            this.showModalReject = false;
            this.submitted = false;
            toast.success("Reject Successfully", {
              autoClose: localStorage.getItem("toastCountdown"),
            });
            this.$router.push("list-edit");
          });
      }
    },
    handleSubmit() {
      this.submitted = true;
      this.reject.remark = "manage-blog";
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      } else {
        this.submitted = false;
        const fieldsDefault = this.fieldsDefaultForCategory.map((field) => {
          if (field.isRequired && field.value === null) {
            this.submitted = true;
          }
          if (field.value !== null) {
            const fieldValue =
              field.inputType === "date"
                ? DateTime.fromJSDate(field.value).toFormat("yyyy-MM-dd")
                : `${field.value}`;
            return {
              fieldUUID: field.uuid,
              fieldValue,
            };
          }
        });
        const fieldsOptional = this.fieldsOptionalForCategory.map((field) => {
          if (field.isRequired && field.value === null) {
            this.submitted = true;
          }
          if (field.value !== null) {
            const fieldValue =
              field.inputType === "date"
                ? DateTime.fromJSDate(field.value).toFormat("yyyy-MM-dd")
                : `${field.value}`;
            return {
              fieldUUID: field.uuid,
              fieldValue,
            };
          }
        });

        if (this.submitted) {
          return;
        }

        const fields = [...fieldsDefault, ...fieldsOptional].filter(function (
          field
        ) {
          return field !== undefined;
        });

        const tags = this.blogForm.tag.map((tag) => {
          return tag;
        });

        const images = [];
        Object.keys(this.blogForm.images).forEach((key) => {
          images.push({
            priority: +key + 1,
            url: this.blogForm.images[key].url,
            originName: this.blogForm.images[key].originName,
          });
        });

        const attachments = [];
        Object.keys(this.blogForm.attachments).forEach((key) => {
          attachments.push({
            url: this.blogForm.attachments[key].url,
            originName: this.blogForm.attachments[key].originName,
          });
        });

        const data = {
          categoryUUID: this.blogForm.category,
          name: this.blogForm.name,
          detail: this.blogForm.detail,
          startDate: this.blogForm.startDate
            ? DateTime.fromJSDate(this.blogForm.startDate).toFormat(
                "yyyy-MM-dd"
              )
            : "",
          endDate: this.blogForm.endDate
            ? DateTime.fromJSDate(this.blogForm.endDate).toFormat("yyyy-MM-dd")
            : "",
          tags,
          fields,
          images,
          thumbnail: this.blogForm.thumbnail,
          attachments,
        };

        if (this.blogForm.uuid) {
          Object.assign(data, { blogUUID: this.blogForm.uuid });
          blogEditSvc
            .updateBlog(data)
            .catch((err) => {
              toast.error(err, {
                autoClose: localStorage.getItem("toastCountdown"),
              });
            })
            .finally(() => {
              this.submitted = false;
              this.get(this.blogForm.uuid);
              toast.success("Updated Successfully", {
                autoClose: localStorage.getItem("toastCountdown"),
              });

              if (this.isSendApprove) {
                this.sendApprove();
              }
            });
        } else {
          blogEditSvc
            .createBlog(data)
            .then((resp) => {
              this.blogForm.uuid = resp.data.data;
            })
            .catch((err) => {
              toast.error(err, {
                autoClose: localStorage.getItem("toastCountdown"),
              });
            })
            .finally(() => {
              toast.success("Created Successfully", {
                autoClose: localStorage.getItem("toastCountdown"),
              });
              this.submitted = false;

              if (this.isSendApprove) {
                this.sendApprove();
              } else {
                this.$router.push("list-edit");
              }
            });
        }
      }
    },
    sendApprove() {
      const data = { blogUUID: this.blogForm.uuid };
      blogEditSvc
        .sendApproveBlog(data)
        .catch((err) => {
          toast.error(err, {
            autoClose: localStorage.getItem("toastCountdown"),
          });
        })
        .finally(() => {
          toast.success("Send Approve Successfully", {
            autoClose: localStorage.getItem("toastCountdown"),
          });
          this.$router.push("list-edit");
        });
    },
    deleteImage(index) {
      this.blogForm.images.splice(index, true);
    },
    deleteAttachment(index) {
      this.blogForm.attachments.splice(index, true);
    },
    isNumber: function (inputType, evt) {
      if (inputType === "number") {
        evt = evt ? evt : window.event;
        const charCode = evt.which ? evt.which : evt.keyCode;
        if (
          charCode > 31 &&
          (charCode < 48 || charCode > 57) &&
          charCode !== 46 &&
          ![37, 38, 39, 40].includes(charCode)
        ) {
          evt.preventDefault();
        } else {
          return true;
        }
      }
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="card">
        <div class="card-body">
          <!-- MODAL APPROVE -->
          <div class="text-sm-end">
            <b-modal
              v-model="showModalApprove"
              title="Approve"
              title-class="text-black font-18"
              body-class="p-3"
              hide-footer
              no-close-on-backdrop
            >
              <form @submit.prevent="handleApprove">
                <div class="row">
                  <div class="col-12">
                    <div class="mb-3">
                      <label>Remark</label>
                      <input
                        id="remark"
                        v-model="approve.remark"
                        type="text"
                        class="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div class="text-end pt-5 mt-3">
                  <b-button variant="light" @click="showModalApprove = false"
                    >Close</b-button
                  >
                  <b-button type="submit" variant="success" class="ms-1"
                    >Approve</b-button
                  >
                </div>
              </form>
            </b-modal>
          </div>
          <!-- MODAL APPROVE -->

          <!-- MODAL REJECT -->
          <div class="text-sm-end">
            <b-modal
              v-model="showModalReject"
              title="Reject"
              title-class="text-black font-18"
              body-class="p-3"
              hide-footer
              no-close-on-backdrop
            >
              <form @submit.prevent="handleReject">
                <div class="row">
                  <div class="col-12">
                    <div class="mb-3">
                      <label>Remark</label>
                      <input
                        id="remark"
                        v-model="reject.remark"
                        type="text"
                        class="form-control"
                        :class="{
                          'is-invalid': submitted && v$.reject.remark.$error,
                        }"
                      />

                      <div
                        v-if="submitted && v$.reject.remark.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="v$.reject.remark.required.$message">{{
                          v$.reject.remark.required.$message
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="text-end pt-5 mt-3">
                  <b-button variant="light" @click="showModalReject = false"
                    >Close</b-button
                  >
                  <b-button type="submit" variant="danger" class="ms-1"
                    >Reject</b-button
                  >
                </div>
              </form>
            </b-modal>
          </div>
          <!-- MODAL REJECT -->

          <form
            class="needs-validation"
            @submit.prevent="
              handleSubmit();
              isSendApprove = false;
            "
          >
            <div class="row">
              <div class="col-md-4">
                <div class="mb-3 position-relative">
                  <label>Category <span class="is-required">*</span></label>
                  <Multiselect
                    :disabled="isDisabled"
                    v-model="blogForm.category"
                    mode="single"
                    :searchable="true"
                    placeholder="Choose..."
                    :options="categoriesOption"
                    @change="setFieldForCategory($event)"
                    :class="{
                      'is-invalid': submitted && v$.blogForm.category.$error,
                    }"
                  />
                  <div
                    v-if="submitted && v$.blogForm.category.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="v$.blogForm.category.required.$message">{{
                      v$.blogForm.category.required.$message
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="mb-3 position-relative">
                  <label>Blog Name <span class="is-required">*</span></label>
                  <input
                    :disabled="isDisabled"
                    v-model="blogForm.name"
                    type="text"
                    class="form-control"
                    :class="{
                      'is-invalid': submitted && v$.blogForm.name.$error,
                    }"
                  />
                  <div
                    v-if="submitted && v$.blogForm.name.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="v$.blogForm.name.required.$message">{{
                      v$.blogForm.name.required.$message
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="mb-3 position-relative">
                  <label>Tag <span class="is-required">*</span></label>
                  <Multiselect
                    :disabled="isDisabled"
                    v-model="blogForm.tag"
                    mode="tags"
                    :close-on-select="false"
                    :searchable="true"
                    placeholder="Choose..."
                    :options="tagsOptions"
                    :class="{
                      'is-invalid': submitted && v$.blogForm.tag.$error,
                    }"
                  />
                  <div
                    v-if="submitted && v$.blogForm.tag.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="v$.blogForm.tag.required.$message">{{
                      v$.blogForm.tag.required.$message
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="mb-3 position-relative">
                  <label>Start Date</label>
                  <datepicker
                    :disabled="isDisabled"
                    ref="startDate"
                    v-model="blogForm.startDate"
                    lang="en"
                    class="form-control"
                  ></datepicker>
                </div>
              </div>
              <div class="col-md-4">
                <div class="mb-3 position-relative">
                  <label>End Date</label>
                  <datepicker
                    :disabled="isDisabled"
                    v-model="blogForm.endDate"
                    :lower-limit="lowerDate"
                    lang="en"
                    class="form-control"
                  ></datepicker>
                </div>
              </div>

              <div v-show="fieldsDefaultForCategory.length" class="col-lg-12">
                <hr class="my-4" />
                <h4 class="card-title">Default Field</h4>
                <p class="card-title-desc"></p>
                <div class="row">
                  <div
                    class="col-md-4"
                    v-for="field of fieldsDefaultForCategory"
                    :key="field.index"
                  >
                    <div class="mb-3 position-relative">
                      <label>
                        {{ field.name }}
                        <span class="is-required">
                          {{ field.isRequired ? "*" : "" }}
                        </span>
                      </label>
                      <div v-if="field.inputType === 'date'">
                        <datepicker
                          v-model="field.value"
                          lang="en"
                          :class="`form-control ${
                            submitted && field.isRequired && !field.value
                              ? 'is-invalid'
                              : ''
                          }`"
                        ></datepicker>
                      </div>
                      <div v-else>
                        <input
                          :disabled="isDisabled"
                          v-model="field.value"
                          :type="text"
                          @keydown="isNumber(field.inputType, $event)"
                          @keyup="isNumber(field.inputType, $event)"
                          @keypress="isNumber(field.inputType, $event)"
                          :class="`form-control ${
                            submitted && field.isRequired && !field.value
                              ? 'is-invalid'
                              : ''
                          }`"
                        />
                      </div>
                      <div
                        v-if="submitted && field.isRequired && !field.value"
                        class="invalid-feedback"
                      >
                        <span
                          v-if="submitted && field.isRequired && !field.value"
                          >{{ field.name }} is required</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div v-show="fieldsOptionalForCategory.length" class="col-lg-12">
                <hr class="my-4" />
                <h4 class="card-title">Optional Field</h4>
                <p class="card-title-desc"></p>
                <div class="row">
                  <div
                    class="col-md-4"
                    v-for="field of fieldsOptionalForCategory"
                    :key="field.index"
                  >
                    <div class="mb-3 position-relative">
                      <label>
                        {{ field.name }}
                        <span class="is-required">{{
                          field.isRequired ? "*" : ""
                        }}</span>
                      </label>
                      <div v-if="field.inputType === 'date'">
                        <datepicker
                          v-model="field.value"
                          lang="en"
                          :class="`form-control ${
                            submitted && field.isRequired && !field.value
                              ? 'is-invalid'
                              : ''
                          }`"
                        ></datepicker>
                      </div>
                      <div v-else>
                        <input
                          :disabled="isDisabled"
                          v-model="field.value"
                          :type="text"
                          @keydown="isNumber(field.inputType, $event)"
                          @keyup="isNumber(field.inputType, $event)"
                          @keypress="isNumber(field.inputType, $event)"
                          :class="`form-control ${
                            submitted && field.isRequired && !field.value
                              ? 'is-invalid'
                              : ''
                          }`"
                        />
                      </div>
                      <div
                        v-if="submitted && field.isRequired && !field.value"
                        class="invalid-feedback"
                      >
                        <span
                          v-if="submitted && field.isRequired && !field.value"
                          >{{ field.name }} is required</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-12">
                <hr
                  v-show="
                    fieldsDefaultForCategory.length ||
                    fieldsOptionalForCategory.length
                  "
                  class="my-4"
                />
              </div>
              <div class="col-md-4">
                <div class="mb-3 position-relative">
                  <label>Thumbnail <span class="is-required">*</span></label>
                  <div class="upload-thumbnail">
                    <img :src="blogForm.thumbnail" />
                  </div>
                  <input
                    :disabled="isDisabled"
                    id="thumbnail"
                    ref="thumbnail"
                    type="file"
                    class="form-control"
                    :class="{
                      'is-invalid': submitted && v$.blogForm.thumbnail.$error,
                    }"
                    accept="image/x-png,image/gif,image/jpeg"
                    @change="uploadThumbnail"
                  />
                  <div
                    v-if="submitted && v$.blogForm.thumbnail.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="v$.blogForm.thumbnail.required.$message">{{
                      v$.blogForm.thumbnail.required.$message
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="mb-3 position-relative">
                  <label>Attachment (PDF)</label>
                  <div
                    class="upload-thumbnail"
                    v-if="blogForm.attachments.length > 0"
                  >
                    <div
                      v-for="(attachments, index) of blogForm.attachments"
                      :key="attachments.index"
                    >
                      <a :href="attachments.url" target="_blank">
                        <i
                          class="bx bxs-file-pdf text-primary h1 align-middle me-3"
                        ></i>
                        <span class="me-5">{{ attachments.originName }}</span>
                      </a>
                      <router-link
                        v-show="!isDisabled"
                        to="#"
                        @click="deleteAttachment(index)"
                        class="delete-attachment"
                      >
                        delete
                      </router-link>
                    </div>
                  </div>
                  <div class="upload-thumbnail" v-else>
                    <img src="" alt="" />
                    <!-- <i class="bx bx-minus h1 align-middle me-3"></i> -->
                  </div>
                  <input
                    :disabled="isDisabled"
                    id="attachments"
                    ref="attachments"
                    type="file"
                    multiple
                    class="form-control"
                    accept="application/pdf"
                    @change="uploadAttachment"
                  />
                </div>
              </div>
              <div class="col-md-12">
                <div
                  class="mb-3 position-relative ckeditor"
                  :class="{
                    'is-invalid': submitted && v$.blogForm.detail.$error,
                  }"
                >
                  <label>Description <span class="is-required">*</span></label>
                  <ckeditor
                    :disabled="isDisabled"
                    v-model="blogForm.detail"
                    :editor="editor"
                    @ready="onEditorReady"
                    :class="{
                      'is-invalid': submitted && v$.blogForm.detail.$error,
                    }"
                  ></ckeditor>
                  <div
                    v-if="submitted && v$.blogForm.detail.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="v$.blogForm.detail.required.$message">{{
                      v$.blogForm.detail.required.$message
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <draggable
                  v-model="blogForm.images"
                  item-key="id"
                  :disabled="isDisabled"
                >
                  <template #item="{ element, index }">
                    <div class="album-drag row">
                      <div class="col-md-12 image">
                        <img :alt="element.uuid" :src="element.url" />
                      </div>
                      <div class="col-md-12 btn-delete">
                        <b-button
                          v-show="!isDisabled"
                          size="sm"
                          variant="danger"
                          @click="deleteImage(index)"
                          >Delete</b-button
                        >
                      </div>
                    </div>
                  </template>
                </draggable>
              </div>
              <div class="col-md-12">
                <div class="mb-3 position-relative">
                  <label>Album</label>
                  <input
                    id="remark"
                    type="file"
                    ref="file"
                    multiple
                    class="form-control"
                    @change="uploadImage"
                  />
                  <!-- <DropZone @drop.prevent="drop" @change="selectedFile" /> -->
                </div>
              </div>
            </div>
            <div class="text-sm-end">
              <button
                :hidden="hiddenButtonSave"
                class="btn btn-success ms-1"
                type="submit"
              >
                Update
              </button>
              <b-button
                :hidden="hiddenButtonSave"
                class="btn btn-warning ms-1"
                @click="
                  isSendApprove = true;
                  this.handleSubmit();
                "
              >
                Update And Send Approve
              </b-button>
              <b-button
                v-show="
                  role !== 'user' && blogForm.statusCode === 'pending_verify'
                "
                class="btn btn-success ms-1"
                a
                @click="showModalApprove = true"
              >
                Approve
              </b-button>
              <b-button
                v-show="
                  role !== 'user' && blogForm.statusCode === 'pending_verify'
                "
                class="btn btn-danger ms-1"
                @click="
                  showModalReject = true;
                  reject.remark = '';
                "
              >
                Reject
              </b-button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </Layout>
</template>
<style>
.multiselect-tag {
  font-family: inherit !important;
}
.form-control[type="text"],
.form-control[type="number"],
.form-control[type="date"] {
  height: 40px !important;
}

.ckeditor.is-invalid .ck-blurred.ck.ck-content {
  border-color: #f46a6a !important;
  padding-right: calc(1.5em + 0.94rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23f46a6a'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23f46a6a' stroke='none'/%3e%3c/svg%3e") !important;
  background-repeat: no-repeat !important;
  background-position: right calc(0.375em + 0.235rem) center !important;
  background-size: calc(0.75em + 0.47rem) calc(0.75em + 0.47rem) !important;
}

.multiselect.is-invalid {
  border-color: #f46a6a;
  padding-right: calc(1.5em + 0.94rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23f46a6a'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23f46a6a' stroke='none'/%3e%3c/svg%3e");

  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.235rem) center;
  background-size: calc(0.75em + 0.47rem) calc(0.75em + 0.47rem);
}
span.is-required {
  color: red;
}

.album-drag {
  display: inline-flex;
  margin: 10px;
  width: 245px !important;
  height: 300px !important;
}
.album-drag .image {
  text-align: center;
}
.album-drag .btn-delete {
  text-align: center;
  margin-top: 10px;
}
.album-drag img {
  max-width: 200px;
  max-height: 200px;
}

div.upload-thumbnail {
  width: auto;
  padding: 15px 0px;
}
div.upload-thumbnail img {
  max-width: 100%;
  height: auto;
}
div.upload-thumbnail .delete-attachment {
  color: red;
}
</style>
